import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';



export const goAnimationAbout = (container: HTMLElement) => {
  ScrollTrigger.normalizeScroll(false);
  gsap.registerPlugin(ScrollTrigger);

  function isMobile() {
    return window.matchMedia("(max-width: 768px)").matches;
  }

  

  setTimeout(() => {
    const startTrigger = container;

    if (!startTrigger) return;

    

    const images = container.querySelectorAll(`.about-block__image`);
    const widthImage = window.screen.width / 8;

    const mainBackground = container.querySelector(`.about-block__dark-bg`);
    const greenBackground = container.querySelector(`.about-block__green-bg`);

    const sticky = document.querySelector(".sticky");
    const stickyContainer = document.querySelector(".sticky__container");

    function isMobile() {
      return window.matchMedia("(max-width: 768px)").matches;
    }
    
    type Position = {
      top: string;
      left?: string;
      right?: string;
      widthImage?: number;
    };
    
    type ImageList = NodeListOf<Element>;
    
    function animateImages(imageList: ImageList, positions: Position[]) {
      imageList.forEach((image, index) => {
        const { top, left, right} = positions[index];
        gsap.to(image, {
          scrollTrigger: {
            scrub: true,
            trigger: startTrigger,
            start: () => `0 10%`,
            end: () => `1500 0%`,
            once: true,
          },
          duration: 3,
          top,
          left,
          right,
          width: `${widthImage}px`,
        });
        gsap.to(image, {
          scrollTrigger: {
            scrub: true,
            trigger: startTrigger,
            start: () => `1000 10%`,
            end: () => `1500 0%`,
            once: true,
          },
          duration: 3,
          opacity: 0,
        })
      });
    }
    
    if (isMobile()) {
      const mobilePositions: Position[] = [
        { top: "60%", left: "44%" },
        { top: "60%", right: "0%" },
        { top: "65%", left: "0%" },
        { top: "50%", right: "7%" },
        { top: "50%", left: "38%" },
        { top: "50%", left: "0%" },
        { top: "30%", right: "0%" },
        { top: "30%", left: "40%" },
        { top: "30%", left: "5%" },
        { top: "15%", right: "25%" },
        { top: "15%", left: "25%" },
        { top: "0%", right: "5%" },
        { top: "0%", left: "40%" },
        { top: "0%", left: "5%" },
      ];
    
      animateImages(images, mobilePositions);
    } else {
      images.forEach((image, i) => {
        gsap.timeline()
          .add(
            gsap.to(image, {
              scrollTrigger: {
                scrub: true,
                trigger: startTrigger,
                start: () => `0 10%`,
                end: () => `1500 0%`,
                once: true,
              },
              duration: 3,
              x: calculatePositionX(i, widthImage),
              y: calculatePositionY(i),
              width: `${widthImage}px`,
            })
          )
          .add(
            gsap.to(image, {
              scrollTrigger: {
                scrub: true,
                trigger: startTrigger,
                start: () => `1000 10%`,
                end: () => `1500 0%`,
                once: true,
              },
              duration: 3,
              opacity: 0,
            })
          );
      });
    }

    gsap
      .timeline()
      .add(
        // бесцветный фон-окружность становится зеленым
        gsap.to(greenBackground, {
          scrollTrigger: {
            scrub: true,
            // markers: true,
            trigger: startTrigger,
            start: () => `500 10%`,
            end: () => `1000 0%`,
            once: true,
          },
          duration: 3,
          backgroundColor: '#45AB34',
        })
      )
      .add(
        // появляются тексты и кнопка
        gsap.to(
          [
            container.querySelector(`.about-block__title`),
            container.querySelector(`.about-block__link`),
            container.querySelector(`.about-block__link-text`),
          ],
          {
            scrollTrigger: {
              scrub: true,
              // markers: true,
              trigger: startTrigger,
              start: () => `1700 10%`,
              end: () => `2000 0%`,
              once: true,
            },
            duration: 3,
            opacity: 1,
          }
        )
      )
      .add(
        // увеличивается текст
        gsap.to(container.querySelector(`.about-block__title`), {
          scrollTrigger: {
            scrub: true,
            // markers: true,
            trigger: startTrigger,
            start: () => `1700 10%`,
            end: () => `2000 0%`,
            once: true,
          },
          duration: 3,
          transform: 'scale(1)',
        })
      )
      .add(
        // "темный фон" из бесцветного становится темным
        gsap.to(mainBackground, {
          scrollTrigger: {
            scrub: true,
            // markers: true,
            trigger: startTrigger,
            start: () => `1000 10%`,
            end: () => `1100 0%`,
            once: true,
          },
          duration: 3,
          backgroundColor: '#141414',
        })
      )
      .add(
        // зеленый фон-окружность уменьшается до 0
        gsap.to(greenBackground, {
          scrollTrigger: {
            scrub: true,
            // markers: true,
            trigger: startTrigger,
            start: () => `2000 10%`,
            end: () => `2500 0%`,
            once: true,
          },
          duration: 3,
          width: '0',
        })
      )
      .add(
        // контейнер становится relative чтобы не наехал на ниже следующие блоки
        gsap.to(container.querySelector(`.about-block__container-info`), {
          scrollTrigger: {
            scrub: true,
            // markers: true,
            trigger: startTrigger,
            start: () => `2400 10%`,
            end: () => `2500 0%`,
            once: true,
            onLeave: () => {
              document.documentElement.classList.remove("scroll");
              if (sticky && stickyContainer) {
                sticky.classList.add('finish');
                stickyContainer.classList.add('finish');
              }
              
              let scrollPos = sticky?.getBoundingClientRect().top;
              if (scrollPos) {
                window.scrollBy(0, scrollPos);
                document.documentElement.classList.add("scroll");
              }
              
            }
          },
          duration: 3,
          position: 'relative',
          height: '100vh',
        })
      );
  }, 2000);
};



const calculatePositionX = (i: number, widthImage: number) => {
  let arr:number[] = []
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    arr = [-3, 0, 3, -2, 2, -3, 0, 3, -3, 0, 3, -3, 0, 3];
  } else {
    arr = [-1, -0.6, -0.3, 0, 0.3, 0.6, 1, -1, -0.6, -0.3, 0, 0.3, 0.6, 1];
  }
  
  const halfScreenWidth = (window.screen.width * 0.8) / 2;
  return arr[i] * halfScreenWidth;
};

const calculatePositionY = (i: number) => {
  let arr:number[] = [];
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    arr = [-3, -3, -3, -2, -2, 0, 0, 0, 2, 2, 2, 3, 3, 3];
  } else {
    arr = [-0.9, -0.7, -0.5, -1, -0.7, -0.5, -0.9, 1, 0.5, 1, 0.7, 0.3, 1, 0.9];
  }
  const halfScreenHight = (window.screen.height * 0.8) / 2;
  return arr[i] * halfScreenHight * 0.9;
};



