import './NextProject.scss';
import { baseURLModx } from '../../redux/services/APIRequestsModx';
import { Link } from 'react-router-dom';
import { connect, WithStore, MapStateToProps, MapDispatchToProps } from '../../redux/services/Imports';
import { Lang } from '../../services/Interfaces';

type NextProjectPropsType = {
  nextId?: string;
  nextPreview?: string;
  lang: Lang;
};

export const NextProject = ({ nextId, nextPreview, lang }: NextProjectPropsType) => {
  const nextText = lang === "En" ? "Next project" : "Следующий проект";
  return (
    <div className="next-project">
      <div className="next-project__container">
        <a href={`/${lang === "En" ? "en/" : ""}case/${nextId}`} className="items-wrap">
          <span className="items marquee">
            <span className="item">{nextText}</span>
            <span className="item">{nextText}</span>
            <span className="item">{nextText}</span>
          </span>
          <span aria-hidden="true" className="items marquee">
            <div className="item">{nextText}</div>
            <span className="item">{nextText}</span>
            <span className="item">{nextText}</span>
          </span>
        </a>
      </div>
      <div className="next-project__preview">
        <img src={`${baseURLModx}/${nextPreview}`} alt="" />
      </div>
    </div>
  );
};

export default WithStore()(connect(MapStateToProps, MapDispatchToProps)(NextProject));