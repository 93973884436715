import { connect, WithStore, MapStateToProps, MapDispatchToProps } from '../../redux/services/Imports';
import { baseURLModx } from '../../redux/services/APIRequestsModx';
import { Props } from './interfaces';
import './ServiceListNew.scss';
import { Link } from 'react-router-dom';

const ServiceListNew = ({ title, list, lang }: Props) => {
  return (
    <div className="service-list-new">
      <h2 className="service-list-new__title">{title}</h2>
      <div className="service-list-new__container">
        {
          list.map(({ title, text, image }, i) => (
            <Link to={`/${lang === "En" ? "en/" : ""}services`} className="service-list-new__item" key={i}>
              <h3 className="service-list-new__item-title">{title}</h3>
              <p className="service-list-new__item-text">{text}</p>
            </Link>
          ))
        }
      </div>
    </div>
  );
};

export default WithStore()(connect(MapStateToProps, MapDispatchToProps)(ServiceListNew));
