import React, { useEffect, useState } from 'react';
import { connect, WithStore, MapStateToProps, MapDispatchToProps } from '../../redux/services/Imports';
import './Main.scss';
import './Main-media.scss';
import { Props } from './interfaces';
import DocumentMeta from 'react-document-meta';
import { STUB_ABOUT, STUB_MAIN, STUB_SERVICES_MAIN } from './constants';
import WeAreSpeaking from '../../blocks/WeAreSpeaking/WeAreSpeaking';
import BlockVideo from '../../components/BlockVideo/BlockVideo';
import SliderRunner from '../../components/SliderRunner/SliderRunner';
import Button from '../../components/Button/Button';
import SliderReviews from '../../components/SliderReviews/SliderReviews';
import Content from '../../components/Content/Content';
import ContentForMedia from '../../components/ContentForMedia/ContentForMedia';
import { EnumUrlsPages } from '../../services/Enum';
import { ServiceData } from '../../redux/services/ServiceRedux';
import {
  IModxMainPage,
  IModxProjectsPage,
  IModxServiceBlock,
  IModxServicesPage,
} from '../../redux/services/InterfacesModx';
import { formatReviews, formatServices, formatTalks } from './functions';
import { ParallaxBlock } from '../../blocks/ParallaxBlock/ParallaxBlock';
import { formatProjects } from '../PageProjects/functions';
import { ICardLink } from '../../components/CardLink/interfaces';
import { MediaContent } from '../../services/GlobalFunctions';
import CardLink from '../../components/CardLink/CardLink';
import { ParallaxTitle } from '../../blocks/ParallaxTitle/ParallaxTitle';
import ServiceList from '../../blocks/ServiceList/ServiceList';
import ServiceListNew from '../../blocks/ServiceListNew/ServiceListNew';
import { ModalFullScreen } from '../../components/ModalFullScreen/ModalFullScreen';
import SliderServices from '../../components/SliderServices/SliderServices';
import { AboutBlock } from '../../blocks/AboutBlock/AboutBlock';

const Main = ({ lang, alertLoaded, widthWindow }: Props) => {
  const [data, setData] = useState<IModxMainPage>(STUB_MAIN);
  const [listPhrases, setListPhrases] = useState<string[]>([]);
  const {
    object: {
      main_screen: {
        title,
        main_button_text,
        show_main_button,
        secondary_button_text,
        show_secondary_button,
      },
      about_block: { testimonials },
      talk_block: { title: titleTalk, links: linksTalk },
      seo,
    },
  } = data;
  const [projects, setProjects] = useState<ICardLink[]>();
  const [servicesData, setServicesData] = useState<IModxServiceBlock>(STUB_SERVICES_MAIN);
  const { title: titleService, services } = servicesData;

  const [openShowReel, setOpenShowReel] = useState(false);

  useEffect(() => {
    ServiceData.getMainPage({ alertLoaded, success, en: lang === 'En' });
    ServiceData.getProjectsPage({ alertLoaded, success: successProjects });
    ServiceData.getServicesPage({ alertLoaded, success: successService, en: lang === 'En' });
  }, [lang]);

  const success = (data: IModxMainPage) => {
    setData(data);

    const listTags = data.object.main_screen.tags.split(',');
    setListPhrases(listTags.length < 12 ? [listTags, listTags].flat() : listTags);
  };

  const successProjects = (data: IModxProjectsPage) => {
    setProjects(formatProjects(data.object.cases.filter((x) => x.main_screen.show === 'true')));
  };

  const successService = (data: IModxServicesPage) => {
    setServicesData(data.object.services_block);
  };

  return (
    <div className="page-main">
      <DocumentMeta {...seo} />

      <BlockVideo
        fullScreen
        hiddenPlay
        autoPlay
        video="/media/MTG_show.mp4"
        poster={require('../../components/BlockVideo/img/stub.webp')}
        content={
          <ContentForMedia
            title={title}
            buttonsBlock={
              <>
                {show_main_button && <Button text={main_button_text} link={EnumUrlsPages.contacts} />}
                {show_secondary_button && (
                  <Button
                    text={secondary_button_text}
                    type="border"
                    color="white"
                    onClick={() => setOpenShowReel(true)}
                  />
                )}
              </>
            }
          />
        }
      />

      <div className="page-main__bg-main">
        <SliderRunner slidesTexts={listPhrases} />
      </div>

      {MediaContent(
        <ParallaxBlock
          title={lang === 'En' ? 'Projects' : 'Проекты'}
          list={projects || []}
          textButton={lang === 'En' ? 'All projects' : 'Все проекты'}
          linkButton={EnumUrlsPages.projects}
        />,
        <div className="page-main__projects-mob">
          <ParallaxTitle title={lang === 'En' ? 'Projects' : 'Проекты'} />

          {projects?.map((item, i) => (
            <CardLink {...item} key={i}></CardLink>
          ))}

          <div className="page-main__projects-mob-button">
            <Button text={lang === 'En' ? 'All projects' : 'Все проекты'} link={EnumUrlsPages.projects} />
          </div>
        </div>
      )}

      <AboutBlock lang={lang} images={STUB_ABOUT} />

      <Content
        padding={{ paddingMain: { top: 160, bottom: 160 }, paddingMob: { top: 40, bottom: 40 } }}
        block={
          <SliderReviews
            title={lang === 'En' ? 'Reviews' : 'О нас говорят'}
            slides={formatReviews(testimonials)}
          />
        }
      />

      {MediaContent(
        <ServiceListNew title={titleService} list={formatServices(services)} height={'5000px'} lang={lang} />,
        <SliderServices title={titleService} slides={services} />
      )}

      <WeAreSpeaking title={titleTalk} list={formatTalks(linksTalk)} />

      {openShowReel && (
        <ModalFullScreen onClose={() => setOpenShowReel(false)}>
          <iframe
            width="100%"
            height="100%"
            src="https://dzen.ru/embed/vMB6ePLvVGDs?from_block=partner&from=zen&mute=1&autoplay=1&tv=1"
            allow="autoplay; fullscreen; accelerometer; gyroscope; picture-in-picture; encrypted-media"
            frameBorder="0"
            scrolling="no"
            allowFullScreen
          ></iframe>
        </ModalFullScreen>
      )}
    </div>
  );
};

export default WithStore()(connect(MapStateToProps, MapDispatchToProps)(Main));
